import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./landing.css"
import "./content-block.css"

import hankintaverkkoTriangle from "../components/divider.svg"
// {
//   "id": "8",
//   "name": "Vglove Nitriilikäsine",
//   "slug": "vglove-nitriilikasine",
//   "categories": ["Nitriilikäsineet"],
//   "description": "- 100kpl / paketti \\n- 10x100kpl / laatikko \\n- Toimitusaika 2-8viikkoa \\n- MoQ 1 000 000 kpl \\n- Kokojakauma Small-XLarge ( 10% / 40% / 40% / 10% ) \\n- EN455 1-4 \\n- EN374 1-5 \\n- ISO13485:2016 & EN ISO13485:2016 \\n- EU Type Examination Certificate \\n- Medical + Food grades \\n- Kaikki dokumentit toimitetaan tilauksen yhteydessä.",
//   "image": ["vglove-nitriilikasine-min.jpg"],
//   "price": ""
// }
const IndexPage = () => (
  <Layout>
    <SEO title="Etusivu" />
    <div className="landing">
      <div className="center">
        <div className="landing-content">
          <h1>
            Kaikki hankinnat yrityksellesi <br />
            yhdestä paikasta.
          </h1>
          <p>Tutustu alta tarkemmin tuotteisiin</p>
          <Link to="/tuotteet" className="btn">
            Tutustu tuotteisiin
          </Link>
        </div>
      </div>
    </div>
    
    <div className="center">
      <div className="content-block">
        <div>
          <h2>
            Vastaamme laajaan kysyntään. <br />
            Kattava hankintaverkosto käyttöösi. <br />
            Luotettava suomalainen kumppani. <br />
          </h2>
        </div>

        <div>
          <p>
            Hankintaverkon tehtävä on tarjota ratkaisu tarpeeseenne
            vaativissakin suojavarustehankinnoissa, joten pyydäthän tarjouksen
            mistä vain PPE tuotteesta.
          </p>
          <p>
            Hankintaverkko on kasannut ulkomaankaupan, logistiikan ja
            rahoituksen ammattilaiset yhteen mahdollistaakseen yrityksenne PPE
            tuotteiden hankinnat globaalisti aina kilpailukykyiseen hintaan ja
            tehokkailla toimituksilla. Toimimme yhteistyössä valmistavien
            tehtaiden kanssa, joka mahdollistaa nopean sekä kustannustehokkaan
            toimituksen.
          </p>
          <p>
            Olemme luotettava suomalainen toimija, jonka kautta hankintoja
            suorittaa jatkuvasti lukuisat terveydenhuollon sekä tukku- ja
            vähittäiskaupan tahot.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
